<template>
    <div class="page-tagsmanage">
        <b-card class="createtagcard">
            <b-icon class="createtagicon" icon="tag"></b-icon>
            <h5 class="createtagtext">Criar Nova Etiqueta</h5>
            <b-button class="createtagbtn" v-b-modal.modal-tagcreate>Criar</b-button>
        </b-card>
        <b-card class="mb-0 border-bottom-grey rounded-bottom-0">
            <div class="tagscardheader">
                <h5 class="tagscardtitle">Etiquetas</h5>
                <div class="tagscardheaderright">
                    <span class="tagscardtexttotal">Total de Etiquetas</span>
                    <span class="tagscardnumbertotal rounded-pill">{{tags.total}}</span>
                </div>
            </div>
        </b-card>
        <b-card class="tagscardcontent rounded-top-0" no-body v-if="tags.length">
            <!-- <b-tabs class="tagstab" card fill>
                <b-tab v-for="tab in tagsTabs" :key="tab.id" :title="`Etiquetas de ${tab.name}`">
                    
                </b-tab>
            </b-tabs> -->
            <div class="tagscardsubheader mt-3">
                <b-row>
                    <b-col class="tagscardcol">
                        <div class="tagscardshtitle">Etiqueta</div> 
                    </b-col>
                    <!-- <b-col class="tagscardcol">
                        <div class="tagscardshtitle">Palavras-Chave</div>
                    </b-col> -->
                    <b-col class="tagscardcol" cols="2"
                    >
                        <div class="tagscardshtitle">Cor</div>
                    </b-col>
                    <b-col class="tagscardcol">
                        <div class="tagscardshtitle">Pré visualizar</div>
                        <!-- <div class="tagscardshboxes">
                            <label><input type="checkbox" name="checkbox-01" /><span></span></label>
                            <b-form-select class="tagscardshselect" :options="selecttagoptions"></b-form-select>
                        </div> -->
                    </b-col>
                </b-row>
            </div>
            <div class="tagscardbody">
                <!-- <b-row class="tagscardbodyrow" v-for="item in tags.filter(tag => tag.type == tab.ref)" :key="item.id"> -->
                <b-row class="tagscardbodyrow" v-for="item in tags" :key="item.id">
                    <b-col class="tagscardcol">
                        <div class="tagscardname">
                            <!-- <b-form-checkbox class="tagscardnamecb" style="display: inline"></b-form-checkbox> -->
                            {{ item.name }}
                        </div> 
                    </b-col>
                    <!-- <b-col class="d-flex align-self-center flex-column">
                        <div class="tagscardkeywords" v-for="keyword in item.keywords" :key="keyword.id">{{keyword}};</div>
                    </b-col> -->
                    <!-- <b-col class="d-flex align-self-center flex-column">
                        <div class="tagscardcreated">{{item.created}}</div>
                    </b-col> -->
                    <b-col class="d-flex align-self-center flex-column" cols="2">
                        <div @click="copyToClipboard(item.color,'Copiado com sucesso!')" class="rounded-circle border" :style="`background-color:${item.color}; width: 1em; height: 1em`" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Copiar código" role="button"></div>
                    </b-col>
                    <b-col class="tagscardcol">
                        <div class="text-white px-3 py-1 rounded-pill" :style="`background-color:${item.color}`" v-if="item.name">
                            {{ item.name }}
                        </div>
                        <div class="tagscardoptionsbtns">
                            <b-button class="tagscardoptionsbtn" @click.prevent="selectTag(item)" v-b-modal.modal-tagedit v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" style="background-color:hsl(94deg 44% 65%)"><b-icon icon="pencil-square"></b-icon></b-button>
                            <b-button class="tagscardoptionsbtn" @click.prevent="selectTag(item)" v-b-modal.modal-tagremove v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove top-0', boundary: 'document' }" title="Remover" style="background-color:hsl(360deg 83% 69%)"><b-icon icon="trash"></b-icon></b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="tagscardpagination" v-if="tags.total > 10">
                <b-pagination class="p-0 m-0" v-model="currentPage" :total-rows="tags.total" :per-page="tagsPerPage" @change="pageChange"></b-pagination>
                <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * tagsPerPage) - tagsPerPage + 1 : currentPage }} - {{(currentPage * tagsPerPage > tags.total) ? tags.total : currentPage * tagsPerPage}} de {{tags.total}} etiquetas cadastradas</div>
            </div>
        </b-card>
        <div class="bg-white rounded-bottom text-center p-3" v-else>
            <b-spinner v-if="loadingTags"></b-spinner>
            <div class="text-secondary" v-else>Nenhuma Etiqueta Encontrada!</div>
        </div>
        <b-modal id="modal-tagcreate" ref="modal-tagcreate" title="Criar Nova Etiqueta" hide-footer header-class="py-0">
            <form @submit.prevent="createTag">
                <!-- <b-form-group label="Palavras-Chave">
                    <b-form-tags class="inputKeywords" v-model="newTag.keywords"></b-form-tags>
                </b-form-group> -->
                <b-row class="text-light-purple">
                    <b-col>
                        <b-form-group label-class="fw-bold" label="Título:">
                            <b-form-input class="border-grey" v-model="newTag.name" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label-class="fw-bold" label="Cor:">
                            <b-form-input class="border-grey" type="color" list="presetColors" v-model="newTag.color"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modal-tagcreate')">Cancelar</b-button>
            </form>
        </b-modal>
        <b-modal id="modal-tagedit" ref="modal-tagedit" title="Editar Etiqueta" hide-footer header-class="py-0" v-if="arrayAux">
            <form @submit.prevent="editTag">
                <!-- <b-form-group label="Tipo:">
                    <b-form-select class="formselect" :options="selecttagtype" v-model="arrayAux.type" required></b-form-select>
                </b-form-group> -->
                <!-- <b-form-group label="Palavras-Chave">
                    <b-form-tags class="inputtagskeywords" v-model="arrayAux.keywords"></b-form-tags>
                </b-form-group> -->
                <b-row class="text-light-purple">
                    <b-col>
                        <b-form-group label-class="fw-bold" label="Título:">
                            <b-form-input v-model="arrayAux.name" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label-class="fw-bold" label="Cor:">
                            <b-form-input type="color" v-model="arrayAux.color" list="presetColors"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modal-tagedit')">Cancelar</b-button>
            </form>
        </b-modal>
        <b-modal id="modal-tagremove" ref="modal-tagremove" title="Remover Etiqueta" hide-footer header-class="py-0" v-if="tagSelected">
            <div class="text-secondary">Tem certeza de que deseja remover essa Etiqueta?</div>
            <b-button class="modal-btn modal-btnsearch" @click="deleteTag">Remover</b-button>
            <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modal-tagremove')">Cancelar</b-button>
        </b-modal>
        <datalist id="presetColors">
            <option>#b8860b</option>/>
            <option>#018b8c</option>
            <option>#ff4500</option>
            <option>#ce5c5c</option>
            <option>#a0522c</option>
            <option>#ab5aa7</option>
            <option>#87cefa</option>
            <option>#ff69b3</option>
            <option>#ffd701</option>
            <option>#32cd33</option>
            <option>#ff8c00</option>
            <option>#d63031</option>
            <option>#a29bfe</option>
            <option>#dfe6e9</option>
            <option>#000000</option>
        </datalist>
    </div>
</template>

<script>
import api from '../services/apiService.js'

export default {
    props: [
        'user'
    ],
    components: {
        
    },
    mounted: function () {
        this.onCloseModal()
        this.getTags()
    },
    methods: {
        selectTag(item) {
            this.tagSelected = item;
            this.arrayAux = Object.assign({}, item);
        },
        async getTags(page = 1) {
            this.loadingTags = true
            const resp = await api.getTags(this.user.channelId || this.user.roleId,page)
            this.loadingTags = false
            // console.log('resp',resp)
            if(!resp || resp.statusCode != 200) {
                this.tags = []
                this.tags.total = 0
            } else {
                this.tags = resp.tags
                this.tags.total = resp.count
            }
        },
        async createTag() {
            let msg

            if(!this.newTag.name) {
                return this.$emit('msg', {
                    text: 'Campo "Título" vazio!',
                    success: false
                })
            }

            const resp = await api.createTag(this.newTag,this.user.channelId || this.user.roleId)
            // console.log('resp',resp)
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                msg = {
                    text:'Etiqueta criada com sucesso!',
                    success: true,
                }
                this.$refs['modal-tagcreate'].hide()
                this.getTags()
            } else if (resp.statusCode == 422) {
                msg = {
                    text: 'Campo "Título" vazio!',
                    success: false
                }
            } else if (resp.statusCode == 406) {
                msg = {
                    text: 'Já existe uma tag com este Título!',
                    success: false
                }
            } else {
                msg = {
                    text: 'Ocorreu um erro! Revise os dados e tente novamente.',
                    success: false
                }
            }
            if(msg)
                this.$emit('msg', msg)
        },
        async editTag() {
            let msg

            if(!this.arrayAux.name) {
                return this.$emit('msg', {
                    text: 'Campo "Título" vazio!',
                    success: false
                })
            }

            const resp = await api.updateTag(this.arrayAux._id,this.arrayAux)
            // console.log('resp',resp)
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                msg = {
                    text:'Etiqueta editada com sucesso!',
                    success: true,
                }
                this.$refs['modal-tagedit'].hide()
                this.getTags()
            } else if (resp.statusCode == 422) {
                msg = {
                    text: 'Campo "Título" vazio!',
                    success: false
                }
            } else if (resp.statusCode == 406) {
                msg = {
                    text: 'Já existe uma tag com este Título!',
                    success: false
                }
            } else {
                msg = {
                    text: 'Ocorreu um erro! Revise os dados e tente novamente.',
                    success: false
                }
            }

            if(msg)
                this.$emit('msg', msg)
        },
        async deleteTag() {
            const resp = await api.deleteTag(this.tagSelected._id)
            // console.log('resp',resp)
            if(!resp || resp.statusCode != 200) {
                this.$emit('msg', {
                    text:'Ocorreu um erro!',
                    success: false,
                })

                return this.getTags()
            }
            this.$refs['modal-tagremove'].hide()
            this.getTags()

            this.$emit('msg', {
                text:'Etiqueta removida com sucesso!',
                success: true,
            })
        },
        pageChange(e) {
            if(e != this.currentPage) {
                this.currentPage = e
                this.getTags(e)
            }
        },
        copyToClipboard(text,alertText) {
            navigator.clipboard.writeText(text)

            this.$emit('msg', {
                text: alertText,
                success:true
            })
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.newTag = {
                    color: '#000'
                }
                this.tagSelected = null
                this.arrayAux = null
            })
        },
    },
    data() {
        return {
            arrayAux: null,
            tagSelected: null,
            newTag: { color: '#000' },
            currentPage: 1,
            tagsPerPage: 10,
            loadingTags: false,
            selecttagtype: [
                { value: 'Contacts', text: 'Etiquetas de Contatos'},
            ],
            selecttagoptions: [
                { value: null, text: 'Selecionar Tudo' },
                { value: 'a', text: 'Desabilitar Etiqueta' },
                { value: 'b', text: 'Editar Etiqueta' },
                { value: 'c', text: 'Remover Etiqueta' },
            ],
            tagsTabs:[
                {
                    name:'Contatos',
                    ref:'Contacts'
                },   
            ],
            tags: [],
        }
    }
}
</script>

<style>
    .tagscardcontent .card-header{
        background-color: #fff;
        padding-top: 30px;
    }
    .tagstab .nav-link{
        color: #555;
    }
    .tagstab .nav-link:hover{
        color: #555;
    }
    .tagstab .nav-tabs .nav-link.active{
        font-weight: 500;
        color: #555;
    }
    .tagscardshboxes{
        display: flex;
        align-items: center;
    }
    .tagscardshboxes select{
        transform: scale(0.75);
        background-color: hsl(339deg 79% 68%);
        color: #fff;
        max-width: 25px;
        border-radius: 3px;
        border: 1px solid hsl(339deg 79% 68%);
        padding: 0 10px;
    }
    .tagscardshboxes label{
        display: flex;
        align-self: center;
    }
    .tagscardshboxes select option{
        background-color: #fff;
        color: #888;
    }
    .tagscardpagination .page-item.active .page-link{
        background-color: hsl(140deg 38% 59%);
        color: #fff;
    }
    .tagscardpagination .page-link{
        color: hsl(243deg 16% 51%);
        border: none;
        border-radius: 50%;
    }
    .tagscardpagination .page-link:hover{
        color: hsl(243deg 16% 51%);
    }
    .inputtagskeywords output{
        display: none;
    }
    .inputtagskeywords .b-form-tag{
        background-color: hsl(94deg 51% 73%);
        border-radius: 5px;
        font-size: 1em;
        align-items: center !important;
    }
    .inputtagskeywords .b-form-tag-remove{
        font-size: 3em;
    }
    #__BVID__55___removed_tags__{
        display: none;
    }
</style>

<style scoped>
    .card{
        border: none;
    }
    .page-tagsmanage>.card:not(:last-child) {
        margin-bottom: 20px;
    }
    .createtagcard{
        background-color: hsl(276deg 33% 70%);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        padding: 10px 0;
    }
    .createtagicon{
        font-size: 7em;
        border: 5px solid #fff;
        border-radius: 50%;
        padding: 10%;
    }
    .createtagtext{
        margin: 15px 0;
    }
    .createtagbtn{
        background-color: #fff;
        color: hsl(276deg 33% 70%);
        border: 0.5px solid #fff;
        padding: 5px 30px;
    }
    .createtagbtn:hover{
        background-color: inherit;
        color: inherit;
        border: 0.5px solid inherit;
    }
    .tagscard .card-body{
        padding: 0;
    }
    .tagscardheader{
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .tagscardtitle{
        color: #333;
        display: flex;
        align-self: center;
    }
    .tagscardheaderright{
        display: flex;
        align-items: center;
    }
    .tagscardtexttotal{
        color: #888;
    }
    .tagscardnumbertotal{
        margin-left: 20px;
        background-color: hsl(232deg 37% 62%);
        color: #fff;
        font-size:1.25em;
        font-weight: 500;
        padding: 5px 20px;
    }
    .tagscardheaderbtn{
        background-color: hsl(94deg 44% 65%);
        border: none;
        padding: 5px 25px;
    }
    .tagscardheaderbtn:hover{
        background-color: hsl(94deg 34% 60%);
    }
    .tagscardsubheader{
        padding: 0px 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eee;
    }
    .tagscardshtitle{
        float: left;
        font-size: 0.9em;
        font-weight: 700;
        color: #777;
    }
    .tagscardbodyrow{
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
    }
    .tagscardcol{
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-all;
    }
    .tagscardnamecb{
        margin-right: 10px;
    }
    .tagscardname{
        color: hsl(232deg 37% 62%);
        font-weight: 500;
    }
    .tagscardkeywords, .tagscardcreated{
        color: hsl(232deg 39% 65%);
    }
    .tagscardoptionsbtn{
        margin: 4px;
        padding: 6px;
        border: none;
        border-radius: 2px;
    }
    .tagscardoptionsbtn:last-child{
        margin-right: 0px;
    }
    .tagscardoptionsbtn:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
    .tagscardpagination{
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        color: #555;
    }
    .modal-btn{
        padding: 5px 20px;
        font-weight: 500;
        margin-top: 20px;
    }
    .modal-btnsearch{
        background-color: hsl(142deg 38% 59%);
        border-color: hsl(142deg 38% 59%);
        color: #fff;
        margin-right: 10px;
    }
    .modal-btncancel{
        background-color: #fff;
        color: #777;
        border-color: #ccc;
    }
    .modal-btncancel:hover{
        background-color: #777;
        color: #fff;
    }
    @-moz-document url-prefix() {
        .groupscardshboxes select{
            transform: scale(0.75);
            /* margin-bottom: 1px; */
            padding-top: 1px;
        }
    }
    .label *
    {
        cursor: pointer;
    }
    input[type="checkbox"]
    {
        opacity: 0;
        position: absolute;
    }
    input[type="checkbox"] + span
    {
        color: #888;
        font-size: 0.9em;
        display: flex;
    }
    label:hover span::before
    {
        -moz-box-shadow: 0 0 2px #ccc;
        -webkit-box-shadow: 0 0 2px #ccc;
        box-shadow: 0 0 2px #ccc;
    }
    input[type="checkbox"] + span::before
    {
        content: "";
        width: 18px;
        height: 18px;
        margin: 0 4px 0 0;
        border: solid 1px #ccc;
        line-height: 14px;
        text-align: center;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
    }
    input[type="checkbox"]:checked + span::before
    {
        color: #666;
        content: "\2713";
        font-size: 12px;
        font-weight: 900;
    }
    input[type="checkbox"]:disabled + span
    {
        cursor: default;
        -moz-opacity: .4;
        -webkit-opacity: .4;
        opacity: .4;
    }
    @media (max-width:425px) {
        .tagscardheader{
            padding: 1em;
            display: block;
        }
        .tagscardtitle,.tagscardheaderright{
            justify-content: center;
        }
        .tagscardnumbertotal{
            padding: 0.1em 0.25em;
        }
        .tagscardnumbertotal:before, .tagscardnumbertotal:after{
            content: none;
        }
    }
</style>